.article-template {
    .main-content {
        width: auto;
        max-width: $desktop_large;
        margin-top: 60px;
        padding: 0 56px;

        @media (max-width: 1000px) {
            padding: 0;
        }

        &.content-list {
            margin-top: 40px;
            padding-bottom: 80px;
            @media (max-width: 1000px) {
                margin-top: 56px;
            }
            @media (max-width: 600px) {
                margin-top: 2px;
            }
            .in-article-newsletter {
                .leade {
                    form {
                        box-sizing: border-box;
                        input[type='submit'] {
                            box-sizing: border-box;
                            @media (max-width: 600px) {
                                margin-top: 14px;
                            }
                            @-moz-document url-prefix() {
                                padding: 17px 0 17px 0;
                            }
                        }
                        input[type='email'] {
                            margin-top: 16px;
                        }
                        .twocol {
                            input {
                                @media (max-width: 600px) {
                                    margin-bottom: 0;
                                }
                            }
                        }
                        .terms-info {
                            padding: 0;
                        }
                    }
                }
            }
        }

        &.content-list-default {
            margin-top: 80px;
            @media (max-width: 834px) {
                margin-top: 56px;
            }
            @media (max-width: 600px) {
                margin-top: 32px;
            }
        }

        &.article {
            .article-columns {
                display: grid;
                grid-template-columns: minmax(0, 1fr) 300px;
                gap: 48px;

                @media (max-width: 1000px) {
                    display: block;
                }

                .articleLeft {
                    font-family: $minion;
                    font-size: 22px;
                    line-height: 1.65;
                    word-break: break-word;

                    a {
                        text-decoration: underline;

                        &:hover {
                            text-decoration: none;
                        }
                    }

                    .article-header {
                        @media (max-width: 1000px) {
                            padding: 0 56px;
                        }
                        @media (max-width: 800px) {
                            padding: 0 32px;
                        }
                        @media (max-width: 600px) {
                            padding: 0 26px;
                        }
                        .series-tag {
                            p {
                                padding: 0;
                                font-family: $proxima;
                                font-size: 18px;
                                font-weight: bold;

                                a {
                                    text-decoration: none;
                                }
                            }
                        }
                    }
                    .articleWrap {
                        figure.article-image.lead-article-image
                            figcaption.caption {
                            @media (max-width: 600px) {
                                padding-top: 16px;
                            }
                        }

                        p {
                            @media (max-width: 1000px) {
                                padding: 0 56px;
                            }
                            @media (max-width: 800px) {
                                padding: 0 32px;
                            }
                            @media (max-width: 600px) {
                                padding: 0 26px;
                            }
                        }

                        .ad-slot {
                            @media (max-width: 1000px) {
                                padding: 0 56px;
                            }
                            @media (max-width: 800px) {
                                padding: 0 32px;
                            }
                            @media (max-width: 600px) {
                                padding: 0 26px;
                            }
                        }

                        .in-article-newsletter {
                            @media (max-width: 1000px) {
                                padding: 0 56px;
                            }
                            @media (max-width: 800px) {
                                padding: 0 32px;
                            }
                            @media (max-width: 600px) {
                                padding: 0 26px;
                            }
                            .leade {
                                h3 {
                                    margin-bottom: 32px;
                                }
                                form {
                                    box-sizing: border-box;
                                    input[type='submit'] {
                                        box-sizing: border-box;
                                        @media (max-width: 600px) {
                                            margin-top: 14px;
                                        }
                                        @-moz-document url-prefix() {
                                            padding: 17px 0 17px 0;
                                        }
                                    }
                                    input[type='email'] {
                                        margin-top: 16px;
                                    }
                                    .twocol {
                                        input {
                                            @media (max-width: 600px) {
                                                margin-bottom: 0;
                                            }
                                        }
                                    }
                                    .terms-info {
                                        padding: 0;
                                    }
                                }
                            }
                        }

                        .author-box.by-line.single-author.footer-author-box {
                            @media (max-width: 1000px) {
                                width: auto;
                                padding: 0 56px;
                            }

                            .author-bio-text {
                                @media (max-width: 1000px) {
                                    padding: 0;
                                }
                            }
                        }

                        .affiliateLink {
                            margin-bottom: 50px;
                        }

                        .dianomi_context {
                            @media (max-width: 1000px) {
                                padding: 0 56px;
                            }
                            @media (max-width: 800px) {
                                padding: 0 32px;
                            }
                            @media (max-width: 600px) {
                                padding: 0 26px;
                            }
                        }
                        .tag-list {
                            @media (max-width: 1000px) {
                                padding: 0 56px;
                            }
                            @media (max-width: 800px) {
                                padding: 0 32px;
                            }
                            @media (max-width: 600px) {
                                padding: 0 26px;
                            }
                        }

                        hr {
                            width: 217px;
                            margin: 75px 0;
                            border-top: 8px solid $black;

                            @media (max-width: 1000px) {
                                margin: 35px 0 35px 56px;
                            }

                            @media (max-width: 800px) {
                                margin-left: 32px;
                            }

                            @media (max-width: 600px) {
                                width: 117px;
                                margin-left: 26px;
                                border-top: 4px solid $black;
                            }
                        }

                        #id_related_pages {
                            @media (max-width: 1000px) {
                                padding: 32px 56px;
                            }

                            @media (max-width: 800px) {
                                padding: 32px;
                            }

                            @media (max-width: 600px) {
                                padding: 32px 26px;
                            }

                            ul {
                                @media (max-width: 1000px) {
                                    padding: 0;
                                }
                            }
                        }
                    }

                    h1.headline {
                        @media (max-width: 800px) {
                            font-size: 40px;
                        }
                        @media (max-width: 600px) {
                            font-size: 32px;
                        }
                    }

                    h2 {
                        @media (max-width: 1000px) {
                            padding: 0 56px;
                        }
                        @media (max-width: 800px) {
                            padding: 0 32px;
                        }
                        @media (max-width: 600px) {
                            padding: 0 26px;
                            font-size: 38px;
                        }
                    }

                    h2.article-tagline {
                        font-size: 32px;
                        font-weight: normal;
                        margin-top: 0 !important;
                        @media (max-width: 1000px) {
                            padding: 28px 0;
                        }

                        @media (max-width: 600px) {
                            font-size: 24px;
                        }
                    }

                    h3 {
                        @media (max-width: 600px) {
                            font-size: 30px;
                        }
                    }

                    .article-line {
                        display: grid;
                        grid-template-columns: 1fr 270px 247px;
                        gap: 24px;
                        align-items: center;

                        @media (max-width: 1400px) {
                            grid-template-columns: 270px 1fr;

                            .author-box {
                                grid-column: 1 / -1;
                            }

                            .pub-date {
                                margin: 10px 0;
                            }
                        }

                        @media (max-width: 600px) {
                            display: block;
                        }

                        a {
                            color: $black;
                            text-decoration: none;

                            &:hover {
                                text-decoration: underline;
                            }
                        }

                        .by-line.single-author {
                            @media (max-width: 600px) {
                                display: grid;
                            }

                            .author-text p.author {
                                padding: 0;
                            }
                        }
                    }

                    .voicesIntro {
                        display: grid;
                        grid-template-columns: 1fr 310px;
                        gap: 24px;
                        align-items: baseline;
                        padding-bottom: 20px;

                        @media (max-width: 1000px) {
                            padding: 0 56px 20px;
                        }

                        @media (max-width: 800px) {
                            padding: 0 32px 20px;
                        }

                        @media (max-width: 600px) {
                            padding: 0 26px;
                            display: block;
                        }

                        p {
                            margin: 0;
                            padding: 0;

                            @media (max-width: 600px) {
                                margin: 10px 0;
                            }
                        }

                        .section {
                            font-family: $proxima;
                            font-size: 22px;
                            font-weight: 700;
                            line-height: 1;

                            @media (max-width: 600px) {
                                font-size: 16px;
                            }
                        }
                    }

                    .sm-newsletter {
                        max-width: 246px;
                        height: 48px;
                        padding: 1px 24px;
                        background-color: #eee;
                        text-align: center;
                        display: flex;
                        align-items: center;
                        gap: 16px;

                        @media (max-width: 600px) {
                            max-width: none;
                            margin-top: 24px;
                            justify-content: center;
                        }

                        i {
                            font-size: 22px;
                        }

                        .newsletter-text {
                            color: $black;
                            font-family: $proxima;
                            font-size: 18px;
                            font-weight: bold;
                            line-height: 1.3;
                        }
                    }

                    .cd-resize-img img {
                        width: auto;
                        height: 100%;
                    }

                    #desktopInArticle-0 {
                        display: block;
                        margin: 0 auto;
                        text-align: center;
                        border-top: 1px solid #ccc;
                        border-bottom: 1px solid #ccc;
                        padding: 2rem 0;
                        min-height: 300px;
                    }

                    ul {
                        margin-top: 0;

                        li {
                            font-size: 18px;
                            padding-bottom: 10px;

                            @media (max-width: 600px) {
                                font-size: 16px;
                            }
                        }
                    }

                    blockquote {
                        margin: 60px 0 60px 56px;
                        padding-left: 48px;
                        border-left: 4px solid $black;
                        color: $black;

                        @media (max-width: 1000px) {
                            padding-left: 0;
                        }

                        @media (max-width: 800px) {
                            margin-left: 40px;
                        }
                    }

                    .pullquote {
                        margin: 0;

                        p {
                            margin: 60px 0;
                            padding: 0 0 0 160px;
                            font-family: $minion;
                            font-size: 32px;
                            font-weight: 500;
                            line-height: 1.2;
                            position: relative;

                            @media (max-width: 1000px) {
                                padding-left: 140px;
                            }

                            @media (max-width: 600px) {
                                padding-left: 80px;
                                font-size: 25px;
                            }

                            @media (max-width: 400px) {
                                padding-left: 50px;
                            }

                            &::before {
                                content: '“';
                                font-family: $proxima;
                                font-size: 240px;
                                font-weight: bold;
                                line-height: 0.8;
                                position: absolute;
                                top: 0;
                                left: 0;

                                @media (max-width: 1000px) {
                                    font-size: 180px;
                                }

                                @media (max-width: 600px) {
                                    font-size: 130px;
                                }

                                @media (max-width: 400px) {
                                    font-size: 100px;
                                    left: -10px;
                                }
                            }
                        }
                    }

                    iframe,
                    .instagram-media,
                    .twitter-tweet {
                        margin-bottom: 20px !important;
                        @media (max-width: 400px) {
                            width: 100%;
                        }
                    }
                    .instagram-media {
                        @media (max-width: 1000px) {
                            margin: 0 56px 12px !important;
                        }
                        @media (max-width: 800px) {
                            margin: 0 32px 12px !important;
                        }
                        @media (max-width: 600px) {
                            width: calc(90% - 2px) !important;
                            margin: 0 26px 12px !important;
                        }
                    }

                    .eg-widget iframe {
                        margin: auto;
                    }

                    .tag-list .nav-tags ul li {
                        margin-right: 10px;
                        padding: 8px 12px;

                        a {
                            text-decoration: none;

                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }

                    .expedia {
                        @media (max-width: 1000px) {
                            padding: 0 56px;
                        }

                        @media (max-width: 800px) {
                            padding: 0 32px;
                        }

                        h3 {
                            margin-top: 0;
                            font-family: $proxima;
                            font-size: 32px !important;
                            font-weight: 700 !important;
                            margin: 0;
                            padding: 0;
                            padding-bottom: 12px;
                            line-height: 1.3;
                        }

                        p {
                            margin-top: 0;
                        }

                        .eg-widget iframe {
                            margin-left: 0 !important;
                        }
                    }

                    .binding-box .amazon-associated-product button {
                        width: 223px;
                    }
                }
                .articleRight {
                    @media (max-width: 1000px) {
                        display: none;
                    }
                }
            }

            h2 {
                margin-top: 0;
                font-family: $minion-condensed;
                font-weight: 400;
                font-size: 46px;
                line-height: 1.3;
            }

            // In article h2
            .articleWrap h2 {
                margin: 50px 0 0;
            }

            h3 {
                margin-top: 0;
                font-family: $minion-condensed;
                font-weight: 400;
                font-size: 38px;
                line-height: 1.3;
            }

            // In article h3
            .articleWrap h3 {
                margin: 50px 0 0;
            }

            p {
                font-family: $minion;
                font-size: 22px;
                line-height: 1.65;
                word-break: break-word;

                a {
                    text-decoration: underline;

                    &:hover {
                        text-decoration: none;
                    }
                }

                /* section */
                &.section {
                    font-family: $proxima;
                    font-size: 22px;
                    font-weight: 700;
                    line-height: 1;

                    a {
                        text-decoration: none;
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }

                /* author and dates */
                .author,
                &.author,
                .photographer,
                &.photographer {
                    margin: 0;
                    padding: 0;
                    color: $black;
                    font-family: $proxima;
                    font-size: 18px;
                    line-height: 1.3;
                    font-weight: 700;

                    @media (max-width: 600px) {
                        font-size: 16px;
                        line-height: 1.1;
                    }

                    a {
                        text-decoration: none;
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }

            .article-columns .articleLeft .articleWrap p.author {
                padding: 0;
            }

            /* disclaimer notice */
            .disclaimer {
                padding: 15px 50px;
                background-color: $button;
                display: grid;
                grid-template-columns: 50px 1fr;
                gap: 30px;

                @media (max-width: 600px) {
                    padding: 10px 25px;
                    gap: 10px;
                }

                i.fa-asterisk {
                    font-size: 50px;
                    align-self: center;
                }

                p {
                    font-family: $proxima;
                    font-size: 18px;
                    line-height: 1.3;

                    strong {
                        margin-bottom: 5px;
                        display: block;
                    }
                }
            }

            .binding-box {
                @media (max-width: 1000px) {
                    padding: 0 56px;
                }

                @media (max-width: 800px) {
                    padding: 0 32px;
                }

                @media (max-width: 600px) {
                    padding: 0 26px;
                }
            }
        }
    }

    /* special report heading */
    .article-special-wrap {
        border-top: 1px solid #ccc;
        background: $white;
        color: $black;

        .article-special {
            width: auto;
            // max-width: 1000px;
            margin: 0 auto;
            padding: 15px 20px;
            background-color: $white;
            display: grid;
            grid-template-columns: 1fr 1fr;

            @media (max-width: 800px) {
                grid-template-columns: 1fr;
                gap: 0;
            }

            .article-special-text {
                width: auto;
                margin: 0 auto 20px;
                padding: 15px 35px;
                background-color: $white;
                color: $black;
                text-align: left;
                display: inline-block;
                grid-column: 1;

                @media (max-width: 800px) {
                    margin-bottom: 0;
                    padding: 15px 0;
                }

                p.hed {
                    margin: 0;
                    background-color: $white;
                    font-family: $minion-condensed;
                    font-size: 28px;
                    font-style: italic;
                    line-height: 1;

                    a {
                        color: $black;
                    }
                }

                p.dek {
                    margin: 0;
                    background-color: $white;
                    font-family: $proxima;
                    font-size: 18px;
                }
            }

            .article-special-logo {
                grid-column: 2;
                justify-self: center;

                img {
                    max-width: 300px;

                    @media (max-width: 400px) {
                        max-width: 100%;
                    }
                }

                p {
                    color: #d3d3d3;
                    font-family: $proxima;
                    font-size: 0.7rem;
                    text-align: center;
                    text-transform: uppercase;
                }
            }

            > :only-child {
                margin-bottom: 0;
                grid-column: span 2;
                display: block;

                p.hed {
                    padding-right: 16px;
                    margin-right: 12px;
                    border-right: 2px solid $black;
                    text-align: right;
                    display: inline-block;
                    vertical-align: middle;

                    @media (max-width: 800px) {
                        margin: 0 auto 5px;
                        padding-right: 0;
                        border-right: none;
                        text-align: center;
                        display: block;
                    }
                }

                p.dek {
                    font-weight: bold;
                    display: inline-block;
                    vertical-align: middle;
                }
            }
        }
    }
}

.desktop-newsletter {
    display: contents;
    @media (max-width: 500px) {
        display: none;
    }
}
.mobile-newsletter {
    display: none;
    @media (max-width: 500px) {
        display: contents;
    }
}
